<template>
  <div class="loans">
    <v-container>
      <v-row>
        <v-switch
          v-model="showFilters"
          label="Mostrar filtros"
          class="mt-0 mb-3"
          color="secondary"
          hide-details
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn class="mx-2" fab right color="secondary" small @click="reload">
          <v-icon> mdi-reload </v-icon>
        </v-btn>
      </v-row>
    </v-container>

    <v-card class="mb-2" v-if="showFilters">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-text-field
              label="Filtre por # de contrato, cedula o nombre de cliente"
              outlined
              hide-details
              dense
              append-icon="mdi-magnify"
              v-model="searchLoanInput"
              clearable
              clear-icon="mdi-close-circle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-btn block color="secondary" @click="searchLoan"> Buscar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" v-if="showFilters">
      <v-card-text>
        <v-row justify="start">
          <v-col cols="12" sm="6" md="4">
            <VueCtkDateTimePicker
              color="orange"
              :id="'createDate'"
              v-model="datePiker.model"
              :label="datePiker.label"
              :range="true"
              :custom-shortcuts="datePiker.shortcut"
              :format="datePiker.format"
              :formatted="datePiker.formatted"
              inputSize="sm"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="users"
              item-text="name"
              item-value="id"
              label="Seleccione Usuario"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.user"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <VueCtkDateTimePicker
              color="orange"
              :id="'eventDate'"
              v-model="datePiker.modelEventDate"
              :label="datePiker.labelEventDate"
              :range="true"
              :custom-shortcuts="datePiker.shortcut"
              :format="datePiker.format"
              :formatted="datePiker.formatted"
              inputSize="sm"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="this.$store.state.status.statusLoan"
              label="Seleccione Estado"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.status"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="role == 'SUPERADMIN'">
            <v-select
              outlined
              :items="offices"
              item-text="name"
              item-value="id"
              label="Seleccione Oficina"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.office"
              @change="changeOffice"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="role == 'SUPERADMIN'">
            <v-select
              outlined
              :items="employees"
              item-text="full_name"
              item-value="id"
              label="Seleccione Asesor"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.employee"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="this.$store.state.status.statusWash"
              label="Seleccione Lavado"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.statusWash"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="categories"
              item-text="name"
              item-value="id"
              label="Seleccione Categoria"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.category"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-btn block color="secondary" @click="searchFilters">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" v-if="showSummary">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ loan_summary.total_loans }}
              </v-avatar>
              TOTAL
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2 darken-1" color="yellow" text-color="white">
              <v-avatar left class="yellow darken-3">
                {{ loan_summary.total_active }}
              </v-avatar>
              ACTIVO
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2" color="green" text-color="white">
              <v-avatar left class="green darken-3">
                {{ loan_summary.total_paid }}
              </v-avatar>
              PAGADO
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2" color="blue" text-color="white">
              <v-avatar left class="blue darken-3">
                {{ loan_summary.total_delivered }}
              </v-avatar>
              ENTREGADO
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2" color="green" text-color="white">
              <v-avatar left class="green darken-3">
                {{ loan_summary.total_returned }}
              </v-avatar>
              DEVUELTO
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-chip class="ma-2" color="red" text-color="white">
              <v-avatar left class="red darken-3">
                {{ loan_summary.total_postponed }}
              </v-avatar>
              APLAZADO
            </v-chip>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" sm="6" md="2">
            TOTAL ALQUILERES <br />
            {{ loan_summary.total_rental | formatNumber }}
          </v-col>
          <v-col cols="12" sm="6" md="2">
            TOTAL DEPÓSITOS <br />
            {{ loan_summary.total_deposit | formatNumber }}
          </v-col>
          <v-col cols="12" sm="6" md="2">
            TOTAL DOMICILIOS <br />
            {{ loan_summary.total_address | formatNumber }}
          </v-col>
          <v-col cols="12" sm="6" md="2">
            TOTAL ABONOS <br />
            {{ loan_summary.payments_sum_value | formatNumber }}
          </v-col>
          <v-col cols="12" sm="6" md="2">
            TOTAL CARTERA <br />
            {{ getWallet() | formatNumber }}
          </v-col>
          <v-col cols="12" sm="6" md="2">
            TOTAL LAVADO <br />
            {{ loan_summary.wash_sum_value | formatNumber }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title v-if="!showFilters">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.state.loan.loans.loans.data"
        :search="search"
        hide-default-footer
        :loading="this.$store.state.loan.loans.isLoading"
        loading-text="Cargando... Por favor espere"
        no-data-text="No hay registro"
        no-results-text="Lo sentimos no hay registro para su busqueda, intente con otro"
        :items-per-page="20"
      >
        <template v-slot:[`item.office`]="item">
          {{ item.item.office.city }}
        </template>
        <template v-slot:[`item.client`]="item">
          {{ item.item.client.first_name + " " + item.item.client.last_name }}
        </template>
        <template v-slot:[`item.dresses`]="item">
          <span v-for="dress in item.item.dresses" :key="dress.id">
            {{ dress.code }} -
          </span>
        </template>
        <template v-slot:[`item.rental_value`]="item">
          {{ item.item.rental_value | formatNumber }}
        </template>
        <template v-slot:[`item.deposit_value`]="item">
          {{ item.item.deposit_value | formatNumber }}
        </template>
        <template v-slot:[`item.payments_sum_value`]="item">
          {{ item.item.payments_sum_value | formatNumber }}
        </template>
        <template v-slot:[`item.status`]="item">
          <v-chip
            class="ma-2"
            :color="getStatusLoanColor(item.item.status)"
            text-color="white"
            small
          >
            {{ getStatusLan(item.item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="blue"
                dark
                v-bind="attrs"
                v-on="on"
                @click="show(item.item.id)"
                class="px-1"
              >
                mdi-eye-outline
              </v-icon>
            </template>
            <span>VER</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="green"
                dark
                v-bind="attrs"
                v-on="on"
                @click="edit(item.item.id)"
                class="px-1"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>EDITAR</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              item.payments_sum_value == item.rental_value &&
              item.status !== 'RETURNED'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="amber lighten-1"
                dark
                v-bind="attrs"
                v-on="on"
                @click="updateStatus(item.item, 'DELIVERED')"
                class="px-1"
              >
                mdi-truck-delivery
              </v-icon>
            </template>
            <span>ENTREGADO</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.item.status == 'DELIVERED'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="success"
                dark
                v-bind="attrs"
                v-on="on"
                @click="updateStatus(item.item, 'RETURNED')"
                class="px-1"
              >
                mdi-clipboard-arrow-left
              </v-icon>
            </template>
            <span>DEVUELTO</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              item.item.status == 'PAID' ||
              item.item.status == 'ACTIVE' ||
              item.item.status == 'POSTPONED'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="red"
                dark
                v-bind="attrs"
                v-on="on"
                @click="updateStatus(item.item, 'POSTPONED')"
                class="px-1"
              >
                mdi-calendar-sync
              </v-icon>
            </template>
            <span>APLAZAR</span>
          </v-tooltip>
          <v-tooltip bottom v-if="role == 'SUPERADMIN'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="red"
                dark
                v-bind="attrs"
                v-on="on"
                @click="deleteLoan(item.item.id)"
                class="px-1"
              >
                mdi-delete-outline
              </v-icon>
            </template>
            <span>ELIMINAR</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-col cols="12" sm="12" md="12">
        <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="8"
          circle
          @input="nextPage"
        ></v-pagination>
      </v-col>
    </v-card>

    <v-btn
      class="mx-2"
      fab
      bottom
      right
      fixed
      color="secondary"
      href="/loans/create"
    >
      <v-icon> mdi-plus </v-icon>
    </v-btn>

    <!-- Dialog change status -->
    <v-dialog v-model="dialogChangeStatus" persistent width="500">
      <v-card>
        <v-card-title>Cambiar Estado</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          Seguro que desea cambiar el estado ha
          <b>{{ getStatusLan(newStatus) }}</b
          >?
          <v-row>
            <v-col cols="10" md="10" v-if="newStatus == 'POSTPONED'">
              <p class="mb-0 text-left">
                Debe seleccionar una nueva fecha de salida.
              </p>
              <v-menu
                v-model="menuNewDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newDate"
                    label="Fecha de salida"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="pt-2"
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="newDate"
                  @input="menuNewDate = false"
                  locale="es-ES"
                  :min="new Date().toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="10" md="10" v-if="newStatus == 'POSTPONED'">
              <v-menu
                v-model="menuReturnDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="returnDate"
                    label="Fecha de entrada"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="pt-2"
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="returnDate"
                  @input="menuReturnDate = false"
                  locale="es-ES"
                  :min="new Date().toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="close"> CANCELAR </v-btn>
          <v-btn text color="primary" @click="updateStatusConfirmation">
            ACEPTAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog delete loan -->
    <v-dialog v-model="dialogDeleteLoan" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Seguro que desea eliminar este alquiler?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDeleteLoan = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteLoanConfirm"
            >Aceptar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_EMPLOYEES } from "@/store/employee";
import { SET_LOANS } from "@/store/loan/loans";
import { SET_OFFICES } from "@/store/offices";
import { SET_ALERT } from "@/store/alert";
import { SET_USERS } from "@/store/user/users";
import { SET_LOAN_SUMMARY } from "@/store/summaries";
import { SET_CATEGORIES } from "@/store/categories";
import { mapGetters } from "vuex";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
export default {
  name: "Users",
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },
  },
  data: () => ({
    showFilters: false,
    showSummary: false,
    dialogChangeStatus: false,
    dialogDeleteLoan: false,
    currentLoan: {},
    newStatus: "",
    menuNewDate: null,
    menuReturnDate: null,
    newDate: "",
    returnDate: "",
    search: "",
    headers: [
      {
        text: "# Contrato",
        align: "start",
        filterable: true,
        sortable: true,
        value: "reference",
      },
      {
        text: "Sede",
        align: "start",
        filterable: true,
        sortable: true,
        value: "office",
      },
      {
        text: "Fecha",
        align: "start",
        filterable: true,
        value: "loan_date",
      },
      {
        text: "Salida",
        align: "start",
        filterable: true,
        value: "event_date",
      },
      {
        text: "Entrada",
        align: "start",
        filterable: true,
        value: "return_date",
      },
      {
        text: "Cliente",
        align: "start",
        filterable: true,
        sortable: true,
        value: "client",
      },
      {
        text: "Productos",
        align: "start",
        filterable: true,
        sortable: true,
        value: "dresses",
      },
      {
        text: "Total Pagar",
        align: "start",
        filterable: true,
        sortable: true,
        value: "rental_value",
      },
      {
        text: "Deposíto",
        align: "start",
        filterable: true,
        sortable: true,
        value: "deposit_value",
      },
      {
        text: "Abonos",
        align: "start",
        filterable: true,
        sortable: true,
        value: "payments_sum_value",
      },
      { text: "Estado", value: "status", filterable: false, sortable: false },
      {
        text: "Acciones",
        value: "actions",
        filterable: false,
        sortable: false,
      },
    ],
    datePiker: {
      model: null,
      label: "SELECCIONE EL RANGO DE FECHA ALQUILER *",
      modelEventDate: null,
      labelEventDate: "SELECCIONE EL RANGO DE FECHA SALIDA",
      format: "YYYY-MM-DD",
      outputFormat: "YYYY-MM-DD",
      formatted: "ll",
      shortcut: [
        { key: "now", label: "Hoy", value: 0 },
        { key: "thisWeek", label: "Esta semana", value: "isoWeek" },
        { key: "last7Days", label: "Ultimos 7 dias", value: 7 },
        { key: "last30Days", label: "Ultimos 30 dias", value: 30 },
        { key: "thisMonth", label: "Este mes", value: "month" },
        { key: "lastMonth", label: "Ultimo mes", value: "-month" },
        { key: "thisYear", label: "Este año", value: "year" },
      ],
    },
    pagination: {
      current: 0,
      total: 0,
    },
    filters: {
      status: "",
      user: "",
      office: "",
      employee: "",
      category: "",
      statusWash: "",
    },
    searchLoanInput: "",
    searchFiltersText: "",
    rules: {
      required: (value) => !!value || "Campo obligatorio.",
    },
    users: [],
    offices: [],
    employees: [],
    categories: [],
    loan_summary: null,
    currentDelete: null,
  }),
  created() {
    this.$store.dispatch("getLoans");
    this.$store.dispatch("getUsersAll");
    this.$store.dispatch("getOfficesAll");
    this.$store.dispatch("getCategoriesAll");
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_LOANS) {
        this.pagination.current = state.loan.loans.loans.current_page;
        this.pagination.total = state.loan.loans.loans.last_page;
      }
      if (mutation.type === SET_USERS) {
        this.users = state.user.users.users;
      }
      if (mutation.type === SET_LOAN_SUMMARY) {
        this.loan_summary = state.summaries.loan_summary;
        this.showSummary = true;
      }
      if (mutation.type === SET_OFFICES) {
        this.offices = state.offices.offices;
      }
      if (mutation.type === SET_EMPLOYEES) {
        this.employees = state.employee.employees;
      }
      if (mutation.type === SET_CATEGORIES) {
        this.categories = state.categories.categories;
      }
    });
  },
  computed: {
    ...mapGetters(["getStatusLan", "getStatusLoanColor"]),
    role() {
      return this.$store.state.user.user.role;
    },
  },
  methods: {
    reload() {
      this.searchLoanInput = "";
      this.filters = {
        status: "",
        user: "",
      };
      this.datePiker.model = null;
      this.searchFiltersText = "";
      this.showFilters = false;
      this.showSummary = false;
      this.$store.dispatch("getLoans");
    },
    searchLoan() {
      if (this.searchLoanInput == "") {
        this.$store.commit(SET_ALERT, {
          message: "Ingrese un valor de busqueda",
          type: "info",
        });
        return;
      }
      this.showFilters = false;
      this.$store.dispatch("getLoans", "search=" + this.searchLoanInput + "&");
    },
    searchFilters() {
      this.searchFiltersText = "";

      if (this.datePiker.model !== null) {
        this.searchFiltersText =
          "min_date=" +
          this.datePiker.model.start +
          "&max_date=" +
          this.datePiker.model.end +
          "&";
      }

      if (this.datePiker.modelEventDate !== null) {
        this.searchFiltersText =
          "min_event_date=" +
          this.datePiker.modelEventDate.start +
          "&max_event_date=" +
          this.datePiker.modelEventDate.end +
          "&";
      }

      if (this.filters.user !== "") {
        this.searchFiltersText += "user=" + this.filters.user + "&";
      }

      if (this.filters.status !== "") {
        this.searchFiltersText += "status=" + this.filters.status + "&";
      }

      if (this.filters.office !== "") {
        this.searchFiltersText += "office=" + this.filters.office + "&";
      }

      if (this.filters.employee !== "") {
        this.searchFiltersText += "employee=" + this.filters.employee + "&";
      }

      if (this.filters.statusWash !== "") {
        this.searchFiltersText += "statusWash=" + this.filters.statusWash + "&";
      }

      if (this.filters.category !== "") {
        this.searchFiltersText += "category=" + this.filters.category + "&";
      }

      if (this.searchFiltersText == "") {
        this.$store.commit(SET_ALERT, {
          message: "Ingrese un valor de busqueda",
          type: "info",
        });
        return;
      }

      this.showFilters = false;
      this.$store.dispatch("getLoans", this.searchFiltersText);
      this.$store.dispatch("getLoanSummary", this.searchFiltersText);
    },
    nextPage() {
      this.$store.dispatch("nextPageLoans", {
        page: this.pagination.current,
        filters: this.searchFiltersText,
      });
    },
    show(id) {
      this.$router.push({ path: `/loans/show/${id}` });
    },
    edit(id) {
      this.$router.push({ path: `/loans/edit/${id}` });
    },
    updateStatus(loan, status) {
      this.currentLoan = loan;
      this.newStatus = status;
      this.dialogChangeStatus = true;
    },
    updateStatusConfirmation() {
      var form = {
        status: this.newStatus,
      };
      if (this.newStatus == "POSTPONED") {
        if (this.newDate == "") {
          this.$store.commit(SET_ALERT, {
            message: "Debe ingresar la nueva fecha de salida",
            type: "error",
          });
          return;
        }
        form.last_date = this.currentLoan.event_date;
        form.new_date = this.newDate;
        form.return_date = this.returnDate;
      }
      this.$store.dispatch("updateLoan", {
        id: this.currentLoan.id,
        form: form,
      });
      this.close();
    },
    close() {
      this.currentLoan = {};
      this.newStatus = "";
      this.dialogChangeStatus = false;
    },
    getWallet() {
      return (
        parseFloat(this.loan_summary.total_rental) -
        (parseFloat(this.loan_summary.total_deposit) +
          parseFloat(this.loan_summary.payments_sum_value))
      );
    },
    deleteLoan(id) {
      this.currentDelete = id;
      this.dialogDeleteLoan = true;
    },
    deleteLoanConfirm() {
      this.$store.dispatch("deleteLoan", this.currentDelete);
      this.dialogDeleteLoan = false;
    },
    changeOffice() {
      this.$store.dispatch(
        "getEmployeesAll",
        "office=" + this.filters.office + "&position=ADVISER"
      );
    },
  },
  components: {
    VueCtkDateTimePicker,
  },
};
</script>
